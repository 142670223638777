import client from 'axios';

export default client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/patient`,
  withCredentials: true
});

export const v2 = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/patient`,
  withCredentials: true
});

export const v2Base = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  withCredentials: true
});

/**
 * Used for S3 request to avoid cors issues
 */
export const axiosWithoutCredentials = client.create();
