import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import SnackbarContext from './SnackbarContext';
import Loadable from './Loadable';

const Production = lazy(() => import('./production/Main'));
const Dashboard = lazy(() => import('./dashboard/Main'));
const Treatment = lazy(() => import('./treatment/Main'));
const Booking = lazy(() => import('./booking/Main'));
const Referral = lazy(() => import('./referral/Main'));
const Retainers = lazy(() => import('./retainers/Main'));
const Invoices = lazy(() => import('./invoices/Main'));
const BookingRequest = lazy(() => import('./bookingRequest/Main'));
const ClosedAccount = lazy(() => import('./ClosedAccount'));
const Documents = lazy(() => import('./documents/Documents'));

const Content = ({ auth }) => {
  const {
    user: { patient }
  } = auth;
  const { i18n } = useTranslation();
  const snackbar = useContext(SnackbarContext);

  useEffect(() => {
    i18n.changeLanguage(auth.user.preferred_locale);
  }, [auth.user.preferred_locale, i18n]);

  if (!patient.treatment_started) {
    return (
      <Suspense fallback={<Loadable loading />}>
        <Switch>
          <Route
            path="/br/:code"
            render={(props) => (
              <BookingRequest
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                bookingDepositPaid={!!auth.user.patient.booking_deposit_paid_at}
                auth={auth}
                snackbar={snackbar}
              />
            )}
          />
          <Route
            path="/(book|bookings)"
            render={(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Booking {...props} auth={auth} snackbar={snackbar} />
            )}
          />
          <Route
            path="/invite"
            render={(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Referral {...props} auth={auth} snackbar={snackbar} />
            )}
          />
          <Route
            path="/factures"
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <Invoices {...props} auth={auth} />}
          />
          <Route
            path="/documents"
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <Documents {...props} auth={auth} />}
          />
          <Route
            render={(props) => {
              if (['closed', 'abandoned'].includes(patient.status)) {
                return <ClosedAccount />;
              }

              if (!patient.check_up_validated_at) {
                return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Booking {...props} auth={auth} snackbar={snackbar} />
                );
              }

              if (
                patient.check_up_decision_practician_at &&
                !patient.check_up_decision_practician
              ) {
                return <ClosedAccount />;
              }

              // eslint-disable-next-line react/jsx-props-no-spreading
              return <Production {...props} auth={auth} />;
            }}
          />
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loadable loading />}>
      <Switch>
        <Route
          path="/br/:code"
          render={(props) => (
            <BookingRequest
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              auth={auth}
              bookingDepositPaid={!!auth.user.patient.booking_deposit_paid_at}
              snackbar={snackbar}
            />
          )}
        />
        <Route
          path="/factures"
          // eslint-disable-next-line react/jsx-props-no-spreading
          render={(props) => <Invoices {...props} auth={auth} />}
        />
        <Route
          path="/documents"
          // eslint-disable-next-line react/jsx-props-no-spreading
          render={(props) => <Documents {...props} auth={auth} />}
        />
        <Route
          path="/invite"
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Referral {...props} auth={auth} snackbar={snackbar} />
          )}
        />
        <Route
          path="/traitement"
          // eslint-disable-next-line react/jsx-props-no-spreading
          render={(props) => <Treatment {...props} auth={auth} />}
        />
        <Route
          path="/contention"
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Retainers {...props} auth={auth} snackbar={snackbar} />
          )}
        />
        <Route
          path="/(book|bookings)"
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Booking {...props} auth={auth} snackbar={snackbar} />
          )}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Route render={(props) => <Dashboard {...props} auth={auth} />} />
      </Switch>
    </Suspense>
  );
};

export default Content;
