import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const styles = (theme) => ({
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    background: 'linear-gradient(to right, #5CAAAE 0%, #AAD9DC 100%)'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    textDecoration: 'none'
  }
});

class MyAppBar extends Component {
  state = {
    anchorEl: null
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, handleDrawerToggle, auth, t } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            component={Link}
            to="/"
            className={classes.title}
          >
            {t('common:common.appBar.title')}
          </Typography>
          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={this.handleClose}
                component="a"
                href={`${process.env.REACT_APP_AUTH_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common:common.menuAppbar.profile')}
              </MenuItem>
              <Divider />
              <MenuItem onClick={auth.logout}>
                {t('common:common.menuAppbar.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const AppBarWithStyles = withStyles(styles)(MyAppBar);

export default withTranslation('common')(AppBarWithStyles);
