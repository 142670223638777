import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/images/joovence-symbole.png';

const useStyles = makeStyles((theme) => ({
  image: {
    width: (props) => props.size,
    animation: `$loading 0.6s ${theme.transitions.easing.easeInOut} infinite`,
    transformOrigin: 'center'
  },
  '@keyframes loading': {
    '0%, 100%': {
      opacity: 1,
      transform: `scale(1.1)`
    },
    '50%': {
      opacity: 0.7,
      transform: `scale(0.9)`
    }
  }
}));

const LoaderLogo = ({ size = 60 }) => {
  const classes = useStyles({ size });
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <img className={classes.image} src={logo} alt="Joovence" />
    </Box>
  );
};

export default LoaderLogo;
