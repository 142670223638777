export const getUrlParam = (param) => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  // handle empty string
  return url.searchParams.get(param) || null;
};

export const redirectToV2 = () => {
  window.location.replace(process.env.REACT_APP_PATIENT_V2_URL);
};
