import React from 'react';
import { CookiesProvider } from 'react-cookie';

import CssBaseline from '@material-ui/core/CssBaseline';

import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from 'styled-components';
import Layout from './components/Layout';
import theme from './utils/theme';

function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CookiesProvider>
              <CssBaseline />
              <Layout />
            </CookiesProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
