const initializeIntercom = (user = null) => {
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
    ...user,
    type: 'patient',
    name: user.fullname,
    email: user.email,
    phone: user.mobile
  });
};

export default initializeIntercom;
