import React, { Fragment } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FolderIcon from '@material-ui/icons/Folder';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { withTranslation } from 'react-i18next';

const drawerWidth = 240;

const styles = (theme) => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    },
    height: '100%'
  },
  toolbar: theme.mixins.toolbar
});

const SideBar = ({
  classes,
  theme,
  mobileOpen,
  handleDrawerToggle,
  auth,
  t
}) => {
  const treatmentContent = (
    <ListItem button component={Link} to="/traitement">
      <ListItemIcon>
        <LocalHospitalIcon />
      </ListItemIcon>
      <ListItemText primary={t('common:common.sideBar.treatment')} />
    </ListItem>
  );
  const content = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.dashboard')} />
        </ListItem>
        {auth.user &&
          auth.user.patient.treatment_started_at &&
          treatmentContent}
      </List>
      <Divider />
      <List>
        <ListItem button component={Link} to="/invite">
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.sponsorship')} />
        </ListItem>
        <ListItem button component={Link} to="/factures">
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.invoices')} />
        </ListItem>
        <ListItem button component={Link} to="/documents">
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.documents')} />
        </ListItem>
        <ListItem
          button
          component="a"
          href="https://shop.joovence.com/?ref=joornal"
          target="_blank"
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.shop')} />
        </ListItem>
        <ListItem button onClick={auth.logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:common.sideBar.logout')} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
            docked: classes.drawerPaper
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

const SideBarWithStyles = withStyles(styles, { withTheme: true })(SideBar);
export default withTranslation('common')(SideBarWithStyles);
