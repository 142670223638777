import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import { SnackbarProvider } from './SnackbarContext';
import Authenticated from './Authenticated';

const Layout = () => (
  <SnackbarProvider>
    <Router>
      <AuthProvider>
        <Authenticated />
      </AuthProvider>
    </Router>
  </SnackbarProvider>
);

export default Layout;
