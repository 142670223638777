const RedirectToAuth = ({ to }) => {
  const encodedURI = encodeURI(window.location.href);

  if (to) {
    return window.location.replace(
      `${process.env.REACT_APP_AUTH_URL}${to}?redirect=${encodedURI}`
    );
  }

  window.location.replace(
    `${process.env.REACT_APP_AUTH_URL}/login?redirect=${encodedURI}`
  );

  return null;
};

export default RedirectToAuth;
