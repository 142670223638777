import common from './common.json';
import bookings from './bookings.json';
import bookingRequests from './bookingRequests.json';
import coupons from './coupons.json';
import invoices from './invoices.json';
import production from './production.json';
import treatments from './treatments.json';
import dashboard from './dashboard.json';
import treatmentPlan from './treatmentPlan.json';
import officeSessions from './officeSessions.json';
import referral from './referral.json';
import retainer from './retainer.json';
import alignerRating from './alignerRating.json';
import photoUpload from './photoUpload.json';
import dialogConfirm from './dialogConfirm.json';
import healthQuestions from './healthQuestions.json';
import documents from './documents.json';

export default {
  common,
  bookings,
  bookingRequests,
  coupons,
  invoices,
  production,
  treatments,
  dashboard,
  treatmentPlan,
  officeSessions,
  referral,
  retainer,
  alignerRating,
  photoUpload,
  dialogConfirm,
  healthQuestions,
  documents
};
